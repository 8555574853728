<template>
	<section
		ref="homme"
		:class="`home detect_deviece_${deviceType} state_${deviceType}`">
		<HeadBar
			title="OneBook"
			:deviceType="deviceType" />
		<section class="home-content">
			<div class="banner">
				<div class="banner-txt">
					Close your eyes and meditate for 5 seconds on your question
					<br />
					Then click the online Book of Answers for clear guidance.
				</div>

				<div class="svgaPage">
					<div id="svga" />
				</div>
				<img
					v-show="!isPlaying"
					src="@/assets/book.png"
					class="book"
					alt=""
					@click="getAnswer()" />
				<div
					v-show="!isPlaying"
					class="mouse-hand" />

				<img
					src="@/assets/m_banner.png"
					alt="" />
				<div
					class="answer-btn"
					@click="getAnswer()">
					Click to view answer
				</div>
			</div>

			<div class="home-intro-row">
				<AdComponent
					ref="ads-1"
					class="m-b-40"
					:ads="adsensConfig.ad1"
					:showDebug="showDebug" />
				<div class="home-intro-title">Unleash the Potential of "The Book of Answers" Online Version</div>
				<div class="home-intro-content">
					"The Book of Answers" online is a powerful tool for gaining insights during life's uncertainties. With just a click, you receive tailored answers to guide your decisions. While the responses may not always directly match your expectations, they encourage self-reflection and offer fresh perspectives with a fun, random twist.
					<br />
					<br />
					Whether facing complex issues or seeking light-hearted entertainment, "The Book of Answers" sparks creativity and personal growth. Stay open to new possibilities and enrich your life with meaningful insights.
					<br />
					<br />
					Start Your Journey Today! Unlock your potential with "The Book of Answers."
				</div>
			</div>

			<div class="home-intro-row">
				<AdComponent
					ref="ads-2"
					class="m-b-40"
					:ads="adsensConfig.ad2"
					:showDebug="showDebug" />

				<div class="home-intro-title">How to Use "The Book of Answers"</div>
				<div class="home-intro-content">
					Define Your Question: Clearly state your issue. Specific questions lead to more relevant answers.
					<br />
					<br />
					Reflect: Take a moment to think about your question and allow answers to emerge.
					<br />
					<br />
					Engage Intuitively: Close your eyes, focus, and randomly flip to a page. This minimizes distractions.
					<br />
					<br />
					Revisit If Needed: If unsatisfied, stay calm and try again.
					<br />
					<br />
					Document Your Findings: Write down your answer for future reference.
					<br />
					<br />
					Follow these steps to find solutions and insights with "The Book of Answers." Trust the process and your intuition!
					<br />
					<br />
				</div>
			</div>
			<AdComponent
					ref="ads-3"
					class="m-b-40"
					:ads="adsensConfig.ad3"
					:showDebug="showDebug" />
			<div class="home-intro-title">Q&A</div>
			<div
				class="home-intro-row"
				style="padding-left: 0; padding-right: 0; padding-top: 0">
				<div class="qa-card">
					<div
						v-for="(item, index) in questions"
						:key="index"
						class="qa-item">
						<button
							class="qa-question"
							@click="toggleAnswer(index)">
							<span class="question-text">{{ item.question }}</span>
							<span
								class="arrow"
								:class="{ open: item.open }" />
						</button>
						<div
							v-if="item.open"
							class="qa-answer">
							{{ item.answer }}
						</div>
					</div>
				</div>
			</div>
			<AdComponent
					ref="ads-4"
					class="m-b-40"
					:ads="adsensConfig.ad4"
					:showDebug="showDebug" />
			<div class="home-intro-title">Something may help</div>
			<div
				class="home-intro-row"
				style="padding-left: 0; padding-right: 0; padding-top: 0">
				<div class="time-page-card-row">
					<div
						v-for="(data, i) in card"
						:key="i"
						class="time-page-card-item"
						@click="goto(data.url)">
						<div class="time-page-card-image">
							<img
								:src="data.img"
								:alt="data.title" />
						</div>
						<div class="time-page-card-content">
							<div class="time-page-card-title">
								{{ data.title }}
							</div>
							<div class="time-page-card-desc">
								{{ data.desc }}
							</div>
						</div>
						<div class="time-page-card-btn">Read more</div>
					</div>
				</div>
			</div>
		</section>

		<FootBar :deviceType="deviceType" />
	</section>
</template>

<script>
import common from '@/mixin/common.js'
import HeadBar from '@/pages/components/head.vue'
import FootBar from '@/pages/components/footer.vue'
import AdComponent from '@/pages/components/AdComponent.vue'

import { mapGetters } from 'vuex'
import '@/css/home.scss'


export default {
	name: 'HomeMain',
	components: {
		HeadBar,
		FootBar,
		AdComponent,
	},
	mixins: [common],
	data() {
		return {
			isPlaying: false, // 播放状态
			questions: [
				{
					question: 'How do I use the "Book of Answers Online Edition"?',
					answer: 'Close your eyes, meditate for 5 seconds, focus on your question, and then click the online edition to receive a random answer. This process can enhance your decision-making ability.',
					open: false,
				},
				{
					question: 'What are some success stories using the "Book of Answers Online Edition"?',
					answer: 'Many people have gained new perspectives and solutions through the online edition, successfully making key decisions that enhance their quality of life.',
					open: false,
				},
				{
					question: 'Are the answers in the online edition reliable?',
					answer: 'The reliability of the answers depends on how you frame your questions and your mindset; specific and clear questions usually yield better answers.',
					open: false,
				},
				{
					question: 'Can I access the "Book of Answers Online Edition" anytime?',
					answer: 'Yes, the online edition can be accessed at any time and from any location, making it convenient for busy modern lifestyles.',
					open: false,
				},
				{
					question: 'Do I need specific devices to use the online edition?',
					answer: 'No, any internet-enabled device, such as a smartphone, tablet, or computer, can easily access the "Book of Answers Online Edition."',
					open: false,
				},
				{
					question: 'In what situations can I use the "Book of Answers Online Edition"?',
					answer: 'You can use the online edition when facing uncertainties in life, work, or relationships; it can provide insights and practical advice.',
					open: false,
				},
		
			],
			card: [
				{
					img: require('@/assets/i1.png'),
					title: '"OneBook": Transformative Success Stories',
					desc: "In today's fast-paced, information-saturated world, where social media is omnipresent, many individuals find themselves feeling lost and anxious in their pursuit of success. However, 'OneBook' has served as a unique tool to guide countless individuals towards clarity. It is more than just a guide; it is a transformative tool for a lifestyle shift, enabling people to pursue happiness and achievement with a stronger sense of purpose. ",
					routename: 'blog1',
				},
				{
					img: require('@/assets/i2.png'),
					title: "OneBook: A Beacon of Wisdom and Guidance for Life's Crucial Decisions",
					desc: "OneBook is not just a collection of wisdom but a guiding light in the lives of many readers. Through real-life customer stories, we see how this book has helped individuals make the right decisions at pivotal moments, whether in career choices, emotional decisions, or repairing family relationships. When Li Lei faced a career dilemma, he turned to OneBooks and found a simple yet profound message that helped him choose the right path. Similarly, Ms. Zhang, through the book’s advice, repaired her strained relationship with her husband. These success stories show that OneBooks is more than just a book; its concise and deep insights have helped countless readers navigate their life's challenges.",
					routename: 'blog2',
				},
				{
					img: require('@/assets/i3.png'),
					title: 'The Accuracy of OneBook: Unveiling the Power of this Mysterious Book',
					desc: 'OneBook is a fascinating and mysterious book that has captured the attention of many seeking guidance in life. From personal dilemmas and emotional uncertainties to career choices and everyday decisions, OneBook provides brief responses that help individuals navigate life’s uncertainties. But how accurate are its predictions? Why do so many claim that this book leads them in the right direction? In this article, we will explore the accuracy of OneBook and analyze its unique role in psychology, philosophy, and personal growth.',
					routename: 'blog3',
				},
				{
					img: require('@/assets/i4.png'),
					title: 'How to Interpret OneBook – Unveiling the Wisdom Behind It',
					desc: "OneBook is a unique tool with a mysterious allure, frequently used by those seeking answers to life's questions. Whether it's emotional dilemmas, career decisions, or everyday uncertainties, the book provides brief yet impactful responses that guide readers toward reflection and self-discovery. Its charm doesn't just lie in the simplicity of its answers but in how one interprets and applies these insights. This article will explore how to interpret OneBook, using SEO-optimized keywords like “OneBooks meaning,” “how accurate is OneBook,” and “how to use OneBook,” while also demonstrating its role in fostering personal growth and decision-making.",
					routename: 'blog4',
				},
				{
					img: require('@/assets/i5.png'),
					title: 'Industry Insight Report: Trends and Insights of "OneBook"',
					desc: "In today's rapidly evolving knowledge economy, the ability to acquire and interpret information is becoming increasingly important. Particularly in areas such as self-improvement, mental health, and interpersonal relationships, people's craving for spiritual nourishment has made various psychology and philosophy books popular. 'OneBook' as a classic work that explores a variety of life questions, has gained widespread attention for its profound wisdom and practical guidance. This report will analyze current industry trends based on data related to 'OneBook' and showcase our company’s expertise in this field",
					routename: 'blog5',
				},
				{
					img: require('@/assets/i6.png'),
					title: 'OneBook: Common Questions and Unique Responses, and Why People Are Drawn to Divination from a Psychological Perspective',
					desc: 'OneBook is a fascinating and interactive divination tool that provides brief yet insightful responses to common life questions. It not only offers philosophical guidance but also draws people seeking clarity and inner reflection. In this article, we will explore OneBook from two angles: first, by listing some common questions and showcasing the book’s unique and detailed responses; second, by analyzing from a psychological perspective why people are drawn to divination books like OneBook and how these tools help them better understand their decisions.',
					routename: 'blog6',
				},
			],
		}
	},
	beforeMount () {
		const arr = require('@/blogs/index.js').default
		this.card = arr.map((e ,i) => ({
			title: e.title,
			desc: e.desc,
			url: e.url,
			img: require(`@/assets/i${i + 1}.png`)
		}))
	},
	computed: {
		...mapGetters(['showDebug', 'deviceType', 'host', 'adsensConfig']),
	},
	async activated() {
		console.log(`${this.$options.name} Component activated-----------`)
		await this.loadAdSenseScript() // 重新加载广告脚本
	},
	async mounted() {
		console.log(`-------------------${this.$options.name} mounted`)
		this.preloadSVGA(1)
		await this.loadAdSenseScript() // 重新加载广告脚本
	},
	deactivated() {
		console.log(`${this.$options.name} Component deactivated-----------`)
	},
	beforeDestroy() {
		console.log(`-------------------${this.$options.name} beforeDestroy`)
	},
	methods: {
		//广告相关start
		async loadAdSenseScript() {
			// 其他需要在初次加载时执行的操作
			// 先检查广告是否已经加载
			if (window.adsbygoogle && window.adsbygoogle.loaded) {
				// 检查广告是否已经加载
				console.log('Adsense script already loaded.')
				this.$eventrack('adscript_loaded', 'expose')
				this.loadAdWithDelay()
				return // 如果已加载，直接返回
			}
			await this.loadScriptConditionally()
		},
		loadScriptConditionally() {
			console.log(this.adsensConfig)
			// 判断广告脚本URL是否存在
			if (!this.adsensConfig?.scriptUrl) {
				console.log('广告脚本的URL不存在,终止加载广告外链')
				this.$eventrack('no_adscript_config', 'expose')
				return
			}
			// 检查该脚本是否已经被添加
			const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`)
			if (existingScript) {
				this.$eventrack('adscript_exist', 'expose')
				console.log('脚本已存在，无需重新添加')
				return
			}

			console.log('准备插入脚本')
			const script = document.createElement('script')
			script.src = this.adsensConfig?.scriptUrl
			script.crossOrigin = 'anonymous'
			script.async = true

			const header = document.getElementsByTagName('head')[0]
			header.appendChild(script)

			this.$eventrack('adscript_add_success', 'expose')
			script.onload = this.loadAdWithDelay.bind(this) // 使用 bind 确保 this 指向正确
			console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作')
		},
		loadAdWithDelay() {
			setTimeout(() => {
				this.displayAd()
			}, 500)
		},
		async displayAd() {
			await this.$nextTick()

			// 获取所有 ads 元素的 refs，过滤掉非广告的 ref
			const adsElements = Object.entries(this.$refs)
				.filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
				.flatMap(([, ref]) => ref) // 展开并获取所有元素

			if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
				console.log('Adsense script not loaded yet, delaying ad display.')
				setTimeout(this.displayAd, 500) // 延迟再次尝试
				return
			}

			console.log(adsElements) // 检查是否包含 <ins> 标签
			adsElements.forEach((ad) => {
				console.log(ad) // 输出每个广告元素
				console.log('ready to push')
				;(window.adsbygoogle = window.adsbygoogle || []).push({})
			})
		},
		//广告相关end

		async playSvga() {
			await this.loadAndPlaySVGA()
		},
		async loadAndPlaySVGA() {
			try {
				const videoItem = await this.preloadSVGA()
				const player = this.createSVGAPlayer()
				player.loops = 1
				player.setVideoItem(videoItem)
				player.startAnimation()
				this.isPlaying = true // 设置为播放状态
				console.log('start')
				// 监听播放完成事件
				player.onFinished(() => {
					this.isPlaying = false // 播放结束时设置状态
					console.log('done')
					this.$router.push({ name: 'answer' })
				})
			} catch (error) {
				console.error('SVGA加载失败:', error)
			}
		},
		preloadSVGA() {
			// const url = "https://ptc-dressup-sg2.obs.ap-southeast-3.myhuaweicloud.com/rest/test/20231214/send.svga"
			const url = '/animate.svga'
			const cacheKey = 'createCache'
			const SVGA = require('svgaplayerweb')

			return new Promise((resolve, reject) => {
				if (this[cacheKey]) {
					resolve(this[cacheKey])
				} else {
					const parser = new SVGA.Parser()
					parser.load(
						url,
						(videoItem) => {
							this[cacheKey] = videoItem
							resolve(videoItem)
						},
						reject,
					)
				}
			})
		},
		createSVGAPlayer() {
			const SVGA = require('svgaplayerweb')

			const elementId = '#svga'
			return new SVGA.Player(elementId)
		},
		toggleAnswer(index) {
			this.questions[index].open = !this.questions[index].open
		},
		getAnswer() {
			this.playSvga(1)
		},
		goto(name) {
			console.log(name);
			this.$router.push('/blogs/'+name)
		},
	},
}
</script>
